import React from "react"
import '../styles/global.scss'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/Title/"
import GoogleMap from '../components/map'

import CellPhone from "../icons/cell_phone.svg"
import Telephone from "../icons/telephone.svg"
import Email from "../icons/email.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Biuro Rachunkowe Jolanta Saska | Cennik Kraków" />

    <section className="section has-bg-color">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="content">
              <h1 className='title is-1 is-spaced'><Title text="Cennik" /></h1>
              <p>Usługi księgowe i rachunkowe czyli rachunkowość dla firm prowadzimy w oparciu o model indywidualnej wyceny dla każdego Klienta. Koszt usług uzależniony jest m.in. od:</p>
        			<ul>
        				<li>formy ksiąg podatkowych - Księgi Handlowe, KPiR, ryczałt </li>
        				<li>formy prawnej - osoba fizyczna, spółka cywilna, Spółka z o.o. </li>
        				<li>branży - działalność usługowa, handlowa, produkcyjna </li>
        				<li>ilości dokumentów i zapisów księgowych </li>
        				<li>ilości zatrudnionych pracowników </li>
        				<li>stopnia złożoności rozliczeń podatkowych </li>
        				<li>innych - firma jest płatnikiem VAT, transakcje WNT, eksport - import, rozlicza projekt unijny, itp. </li>
        			</ul>
        			<h2>Przykładowe  cenniki:</h2>
        			<p><strong>Prowadzenie książki przychodów  i rozchodów z vat:</strong></p>
        			<ul>
        				<li>do 50 dokumentów miesięcznie -  250 zł netto</li>
        				<li>od 51-150 dokumentów miesięcznie –   zł  400 netto</li>
        				<li>od 151-250 dokumentów miesięcznie – zł  600  netto</li>
        			</ul>
        			<p><b>Prowadzenie ksiąg handlowych firm o profilu handlowym i usługowym:</b></p>
        			<ul>
        				<li>do 50 dokumentów miesięcznie (zakup, sprzedaż, wyciągi bankowe, raport kasowy) – cena podstawowa wynosi 800 zł netto</li>
        				<li>od 51-150 dokumentów miesięcznie –   zł 1 600 netto</li>
        				<li>od 151-250 dokumentów miesięcznie – zł 2 400 netto</li>
        				<li>od 251-350 dokumentów miesięcznie – zł  3 000 netto</li>
        			</ul>
        			<p><b>Za każdorazowe sporządzenie bilansu i rachunku wyników   - 800 zł netto</b></p>
        			<p><b>Prowadzenie ksiąg handlowych firm realizującym projekt unijny koszt obsługi księgowej zwiększany jest o 100% ceny podstawowej za każdy realizowany projekt</b></p>
        			<p><b>Obsługa kadrowo-płacowa każdego pracownika niezależnie od formy zatrudnienia do 2 osób wliczona jest w kwotę opłaty miesięcznej podstawowej. Za każdą dodatkową osobę dopłata wynosi – 30 zł netto od osoby.</b></p>
        			<p><b>Reprezentowanie podatnika przed urzędem, zusem w czasie kontroli podatkowej 70 zł netto  za godzinę</b></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" className="section contact-section">
      <div className="contact-section__half"></div>
      <div className="container">
        <div className="columns">
          <div className="column is-5">
            <div className="contact-section__content has-text-white">
              <h3 className='title is-1 is-spaced has-text-white'><Title text="Kontakt" /></h3>
              <div className="has-text-white contact-info">
                Jolanta Saska - Biuro Rachunkowe <br />
                ul. Vetulaniego 1A / pok. 210 <br />
                31-227 Kraków <br />
                NIP: 945-13-43-698 <br/>
              </div>
              <div className="columns is-multiline">
                <div className="column is-6">
                  <div className="contact-item">
                    <CellPhone width="32px"  height="32px" />
                    <a href="tel:+48502276292">502 276 292</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Telephone width="32px"  height="32px" />
                    <a href="tel:+48124202130">12 420 21 30</a>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="contact-item">
                    <Email width="32px"  height="32px" />
                    <a href="mailto:jolanta.saska@gmail.com">jolanta.saska@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-7">
            <div className="map-container">
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default IndexPage
